













































































































































































































































import {Component, Vue} from "vue-property-decorator";
import SaveW8BENRequestDTO from "@/dto/archive/SaveW8BENRequestDTO";
import RegexUtils from "@/utils/RegexUtils";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import PortalInput from "@/components/common/PortalInput.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import SaveW9RequestDTO from "@/dto/archive/SaveW9RequestDTO";
import AddressDTO from "@/dto/AddressDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import {HolderType} from "@/dto/HolderType";
import {BankAccountType} from "@/dto/BankAccountType";
import Application from "@/state/Application";

const AppModule = namespace("App");

@Component({
	components: {PortalInput}
})
export default class FormW8BEN extends Vue {

	private successful = false;

	private message = "";

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private request = new SaveW8BENRequestDTO();

	private mailingAddressIsDifferent = false;

	private exists = true;

	private processed = false;

	mounted(){
		this.request.publicId = this.$route.params.publicId;
		Application.startLoading()
		this.ensureExists()
			.then(() => this.loadForm())
			.then(() => Application.stopLoading());
	}

	private loadForm() {
		return DocumentService.loadPreparedPublicForm<SaveW8BENRequestDTO>(this.$route.params.publicId).then(
			success => {
				this.request = success.data;
				this.request.publicId = this.$route.params.publicId;
				if (!this.request.permanentAddress) {
					this.request.permanentAddress = new AddressDTO();
				}
			},
			error => processError(error, this)
		);
	}

	ensureExists(){
		return DocumentService.existsByPublicId(this.$route.params.publicId).then(
			success => this.exists = success.data,
			error => this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
		)
	}

	handleName(event: KeyboardEvent) {
		if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key)) {
			event.preventDefault();
		}
	}

	save() {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			DocumentService.saveW8ben(this.request).then(
				success => {
					this.successful = true;
					this.processed = true;
				},
				error => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				}
			).then(() => this.stopLoading());
		})
	}

	handleSsn(event: KeyboardEvent) {
		let current = this.request.usSsnOrItin ? this.request.usSsnOrItin : "";
		if (RegexUtils.testSsnPreSeparator(current)) {
			current = current.concat('-')
			this.request.usSsnOrItin = current;
		}
		const next = (current).concat(event.key)
		if (!RegexUtils.testSsn(next)) {
			event.preventDefault();
		}
		if(next.length > 11){
			event.preventDefault();
		}
	}

}
