import AddressDTO from "@/dto/AddressDTO";

export default class SaveW8BENRequestDTO {

    publicId = '';

    name = '';

    citizenship = '';

    permanentAddress = new AddressDTO();

    mailingAddress = new AddressDTO();

    usSsnOrItin = '';

    foreignTin = '';

    isFtinRequired = false;

    referenceNumbers = '';

    birthDay = '';

    residentship = '';

    articleAndParagraph = '';

    rate: number | null = null;

    typeOfIncome = '';

    additionalConditionsExplanation = '';

    hasCapacityToSign = false;

    signerName = "";

}